import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faSpinner } from '@fortawesome/free-solid-svg-icons';
import useApiFetch from '../../ApiFetch';

const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
function ProfileEmail(props) {
    const apiFetch = useApiFetch();

    const [profileEmail, setProfileEmail] = useState('');

    const [savingProfileEmail, setSavingProfileEmail] = useState(false);
    const [profileEmailSaveResult, setProfileEmailSaveResult] = useState(null);
    const [profileExists, setProfileExists] = useState(null);
    const [profileEmailSearching, setProfileEmailSearching] = useState(false);
    const [savingError, setSavingError] = useState(false);

    useEffect(() => {
        if (props.email) {
            console.log('set email from props', props.email);
            setProfileEmail(props.email);
        }
    }, [props]);

    const saveProfileEmail = async (e) => {
        e.preventDefault();
        setSavingProfileEmail(true);

        await apiFetch.post('/api/profile/profileemail', profileEmail, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((resp) => {
                setProfileEmailSaveResult('Success');
                setTimeout(() => { setProfileEmailSaveResult(null); }, 5000);
                if (props.saved)
                    props.saved(resp.data);
            })
            .catch((ex) => {
                setProfileEmailSaveResult('Fail');
                setTimeout(() => { setProfileEmailSaveResult(null); }, 5000);
                setSavingError(ex.response && ex.response.data && ex.response.data.error ? ex.response.data.error.message : null);
                setTimeout(() => { setSavingError(null); }, 5000);

            })
            .finally(() => {
                setProfileEmail(props.email);
                setSavingProfileEmail(false);
            });
    };
    const emailBlur = () => {
        if (isEmail(profileEmail) && profileEmail.trim().toLowerCase() !== props.email) {
            setTimeout(profileEmailSearch, 100);
        }
        else {
            setProfileExists(null);
        }
    };
    const profileEmailSearch = async (e) => {
        if (e)
            e.preventDefault();

        setProfileExists(null);
        setProfileEmailSearching(true);
        //setApplied(false);
        try {
            setProfileExists(await profileEmailExists());
        }
        catch {
            //    setProfile(null);
            //    if (props.applyProfile) {
            //        props.applyProfile(null);
            //    }
        }
        //setApplied(true);
        setProfileEmailSearching(false);
    };
    const profileEmailExists = async () => {
        return await apiFetch.get('api/profile/emailexists?email=' + encodeURIComponent(profileEmail))
            .then((resp) => {
                return resp.data;
            })
            .catch(() => { return false });
    }

    return (
        <form onSubmit={saveProfileEmail} className="card mt-3">
            <div className="card-header">
                Change Email
            </div>
            <div className="card-body">
                <div className="row g-3">
                    <div className="col-sm-12">
                        <label htmlFor="email" className="form-label">Email</label>
                        <div className="input-group">
                            <input type="email" className={`form-control ${profileEmail === props.email ? 'is-valid' : profileExists === null ? '' : (profileExists ? 'is-invalid' : isEmail(profileEmail) ? 'is-valid' : 'is-invalid')}`} id="email" onBlur={emailBlur} onChange={(e) => { setProfileExists(null); setProfileEmail(e.target.value); }} name="email" value={profileEmail} />
                            {profileEmailSearching ?
                                <span className="input-group-text bg-transparent" id="basic-addon2"><FontAwesomeIcon icon={faSpinner} spin className="" /></span>
                                :
                                null}
                        </div>
                        {profileExists !== null && profileExists
                            ?
                            <div className="col-sm-12 mt-3">
                                <ul className="list-group">
                                    <li className="list-group-item lh-sm text-bg-warning text-center">
                                        Another profile for <b>{profileEmail}</b> already exists.<br />
                                    </li>
                                </ul>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
            <div className="card-footer text-body-secondary text-end">
                {savingError ?
                    <span className="text-danger pe-2">
                        {savingError}
                    </span>
                    :
                    null
                }
                <button type="submit" className="btn btn-secondary btn-sm position-relative" disabled={props.email === profileEmail || (profileExists !== null && profileExists)}>
                    <FontAwesomeIcon icon={savingProfileEmail ? faSpinner : faFloppyDisk} spin={savingProfileEmail} className="me-2" />
                    Save
                    {profileEmailSaveResult
                        ?
                        <span className={`position-absolute top-0 start-100 translate-middle p-2 border border-light rounded-circle ${profileEmailSaveResult === 'Success' ? "bg-success" : profileEmailSaveResult === 'Fail' ? "bg-danger" : ""}`}>
                            <span className="visually-hidden">Saved</span>
                        </span>
                        : null
                    }
                </button>
            </div>
        </form>
    );

}
export default ProfileEmail;
