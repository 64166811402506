import React, { useState, useEffect } from 'react';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFloppyDisk, faPlus, faUserPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import useApiFetch from '../../ApiFetch';
import moment from 'moment';
import StudentCard from './StudentCard';

export default function AddStudentCard(props) {
    const { profile, profileStudentAdded } = props;
    const apiFetch = useApiFetch();

    const [students, setStudents] = useState([]);

    const [findStudentId, setFindStudentId] = useState(null);
    const [findDOB, setFindDOB] = useState(null);
    const [findingStudent, setFindingStudent] = useState(false);


    const [modalAdd, setModalAdd] = useState(false);

    const toggleModalAdd = () => setModalAdd(!modalAdd);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {

        //get the profile data
    //    await apiFetch.get('/api/profile/students')
    //        .then(resp => {
    //            setStudents(resp.data);
    //            console.log(resp.data);
    //        });
    };

    const findStudent = async (e) => {
        e.preventDefault();
        setFindingStudent(true);
        await apiFetch.get('/api/profile/students/find?associationId=' + encodeURIComponent(findStudentId) + '&dob=' + encodeURIComponent(moment(findDOB).format('YYYY-MM-DD')))
            .then(resp => {
                setStudents(resp.data);
                console.log(resp.data);
                setFindingStudent(false);
            })
            .finally(() => {
                setFindingStudent(false);
            });

    };

    const addProfileStudent = async (e, studentId) => {
        await apiFetch.post('/api/profile/student', { profileId: profile.profileId, studentId: studentId, isPrimary: false })
            .then((resp) => {
                console.log('api/profile/student', resp);

                if (profileStudentAdded)
                    profileStudentAdded();

                setFindStudentId(null);
                setFindDOB(null);
                setStudents([]);
                toggleModalAdd();
            })
            .catch((ex) => {
            })
            .finally(() => {
            });
    }

    return (
        <>

            <div className="col">
                <div className="card h-100 align-items-center" onClick={toggleModalAdd} style={{ cursor: "pointer" }}>
                    <div className="row g-0 text-center">
                        <div className="col mt-5">
                            {/*<img src="https://www.sidekick.events/assets/images/sidekick_logo_right.png" className="img-fluid rounded-start" alt="..." />*/}

                            {/*d-md-block*/}
                            <FontAwesomeIcon icon={faPlus} className="display-1" />
                            <h5 className="pt-2 py-5">Add Student</h5>
                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={modalAdd} toggle={toggleModalAdd} size="md" fade>
                <ModalHeader toggle={toggleModalAdd}>
                    <FontAwesomeIcon icon={faUserPlus} className="pe-2" />Add Student
                </ModalHeader>
                <ModalBody className="">
                    <h4 className="mb-4">Find student</h4>
                    {/*<form onSubmit={() => alert('saveProfileInfo')} className="card">*/}
                    {/*    <div className="card-header">*/}
                    {/*        Personal Info*/}
                    {/*    </div>*/}
                    {/*    <div className="card-body">*/}
                            <div className="row g-3 mb-4">
                                <div className="col-sm-6">
                            <label htmlFor="studentId" className="form-label">Member ID or BB#:</label>
                                    {/*<input type="text" className={`form-control ${(profileInfo.firstName ?? '') !== '' ? 'is-valid' : 'is-invalid'}`} id="firstName" onChange={(e) => setProfileInfo({ ...profileInfo, [e.target.name]: e.target.value })} name="firstName" value={profileInfo.firstName ?? ''} />*/}
                            <input type="text" className={`form-control ${(findStudentId ?? '') !== '' ? 'is-valid' : 'is-invalid'}`} id="studentId" onChange={(e) => setFindStudentId(e.target.value)} value={findStudentId ?? ''} />
                                </div>
                                <div className="col-sm-6">
                                    <label htmlFor="dateOfBirth" className="form-label">Date of Birth:</label>
                                    {/*<input type="text" className={`form-control ${(profileInfo.lastName ?? '') !== '' ? 'is-valid' : 'is-invalid'}`} id="lastName" onChange={(e) => setProfileInfo({ ...profileInfo, [e.target.name]: e.target.value })} name="lastName" value={profileInfo.lastName ?? ''} />*/}
                            {/*<input type="text" className={`form-control ${('' ?? '') !== '' ? 'is-valid' : 'is-invalid'}`} id="lastName" onChange={(e) => alert('setProfileInfo({ ...profileInfo, [e.target.name]: e.target.value })')} name="lastName" value={'' ?? ''} />*/}
                            <input type="date" required className={`form-control ${moment(findDOB).isValid() ? 'is-valid' : 'is-invalid'}`} id="dateOfBirth" value={findDOB} onChange={(e) => setFindDOB(e.target.value)} name="dateOfBirth" />
                        </div>
                        <div className="col text-end">
                            <button type="submit" className="btn btn-secondary" disabled={(findStudentId ?? '') === '' || !moment(findDOB).isValid()} onClick={findStudent}>
                                <FontAwesomeIcon icon={findingStudent ? faSpinner : faMagnifyingGlass} spin={findingStudent} className="me-2" />
                                Find Student
                            </button>
                        </div>
                    </div>
                    {students && students.length > 0 ?
                        <>
                            <hr />
                            {students.map((s, i) =>
                                <StudentCard student={s} key={i} actions={[
                                    <button type="button" className="btn btn-secondary"
                                        style={{ "--bs-btn-padding-y": ".25rem", "--bs-btn-padding-x": ".5rem", "--bs-btn-font-size": ".75rem" }} onClick={(e) => addProfileStudent(e, s.studentId)} >
                                        <FontAwesomeIcon icon={faPlus} className="pe-1" />
                                        Add To Profile
                                    </button>,
                                //    <button type="button" className="btn btn-secondary"
                                //        style={{ "--bs-btn-padding-y": ".25rem", "--bs-btn-padding-x": ".5rem", "--bs-btn-font-size": ".75rem" }} onClick={() => alert('test')} >
                                //        <FontAwesomeIcon icon={faPlus} className="pe-1" />
                                //        Test
                                //    </button>,
                                ]} />
                            )}
                        </>
                        :
                        null
                        }
                    {/*<hr />*/}
                    {/*    <div className="row g-3">*/}
                    {/*    */}{/*</div>*/}
                    {/*    */}{/*<div className="card-footer text-body-secondary text-end">*/}
                    {/*        <button type="submit" className="btn btn-secondary position-relative" disabled={('' ?? '') === '' || ('' ?? '') === ''}>*/}
                    {/*            <FontAwesomeIcon icon={false ? faSpinner : faFloppyDisk} spin={false} className="me-2" />*/}
                    {/*            Save*/}
                    {/*            {''*/}
                    {/*                ?*/}
                    {/*                <span className={`position-absolute top-0 start-100 translate-middle p-2 border border-light rounded-circle ${'Success' === 'Success' ? "bg-success" : 'Fail' === 'Fail' ? "bg-danger" : ""}`}>*/}
                    {/*                    <span className="visually-hidden">Saved</span>*/}
                    {/*                </span>*/}
                    {/*                : null*/}
                    {/*            }*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*</form>*/}
                    {/*</div>*/}

                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleModalAdd}>
                        Cancel
                    </Button>
                    {/*<Button color="primary" onClick={teamSelected} disabled={selectedTeam ? false : true}>*/}
                    {/*    Select Team*/}
                    {/*</Button>*/}
                </ModalFooter>
            </Modal>

        </>
    );

}
