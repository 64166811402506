import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { useNavigate, Link } from 'react-router-dom';
/*import QRScanner from './QRScanner.tsx'*/

export default function EventCard(props) {
    const { event } = props;
    const navigate = useNavigate();

    return (
        <div className="col d-flex align-items-start pb-5 px-4">
            <div className="icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                {/*<FontAwesomeIcon icon={faCalendarDays} />*/}
                <h2 className="text-secondary fw-bold m-0 p-0">{moment(event.startDate).date()}</h2>
            </div>
            <div>
                <h3 className="fs-2 text-body-emphasis">{event.title}</h3>
                <p style={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: event.shortDescription }} />
                <Link to={'/events/' + event.code} className="btn btn-primary" type="button">
                    Event Details
                    {/*<svg className="bi ms-2" width="24" height="24"><use xlink:href="#arrow-right-short"></use></svg>*/}
                </Link>
                <p>
                    <small>{moment(event.startDate).format('LL')}{event.startTime ? ' - ' + moment(event.startTime, 'HH:mm:ss').format('h:mm A') : null}</small>
                </p>

            </div>
        </div>
    );
};
