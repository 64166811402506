import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCalendar, faClock, faLocationDot, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';
import moment from 'moment';
import { NumericFormat, PatternFormat } from 'react-number-format';
import useApiFetch from '../../ApiFetch';

export default function Event(props) {
    const { event } = props;
    const { eventCode } = useParams();
    const navigate = useNavigate();
    const apiFetch = useApiFetch();

    const [loading, setLoading] = useState(false);
    const [eventData, setEventData] = useState([]);
    const [modal, setModal] = useState(false);

    const [validated, setValidated] = useState(false);
    const [registration, setRegistration] = useState({ firstName: '', lastName: '', email: '', company: '', phone: '' });
    const [registering, setRegistering] = useState(false);
    const [registeredData, setRegisteredData] = useState(null);

    const modalToggle = () => {
        setModal(!modal)
        setRegisteredData(null);
        setValidated(false);
        setRegistering(false);
        setRegistration({ firstName: '', lastName: '', email: '', company: '', phone: '' });
    };

    useEffect(() => {
        setLoading(true);
        setRegistering(false);

        getData();
    }, []);
    const getData = async () => {
        await apiFetch.get('/api/event/' + encodeURIComponent(eventCode))
            .then(resp => {
                setEventData(resp.data);
                setLoading(false);
            });
    };
    const getEventDates = () => {
        if (eventData) {
            if (eventData.endDate) {
                //if we have an end date, check to see if the start and end dates are the same, if they are, show just one date
                if (eventData.endDate === eventData.startDate) {
                    return moment(eventData.startDate).format('MMMM D');
                }
                else {
                    //if the month is the same, show Month Start-End, otherwise, show Month Start - Month End
                    if (moment(eventData.startDate).month() === moment(eventData.endDate).month()) {
                        return moment(eventData.startDate).format('MMMM') + ' ' + moment(eventData.startDate).format('D') + '-' + moment(eventData.endDate).format('D');
                    }
                    else {
                        return moment(eventData.startDate).format('MMMM D') + '-' + moment(eventData.endDate).format('MMMM D');
                    }
                }
            }
            else {
                return moment(eventData.startDate).format('MMMM D');
            }
        }

    };
    const getEventTimes = () => {
        if (eventData && eventData.startTime) {
            if (eventData.endTime) {
                //if we have an end date, check to see if the start and end dates are the same, if they are, show just one date
                if (eventData.endTime === eventData.startTime) {
                    return moment(eventData.startTime, 'HH:mm:ss').format('h:mm A');
                }
                else {
                    return moment(eventData.startTime, 'HH:mm:ss').format('h:mm A') + '-' + moment(eventData.endTime, 'HH:mm:ss').format('h:mm A');
                }
            }
            else {
                return moment(eventData.startTime, 'HH:mm:ss').format('h:mm A');
            }
        }
        else {
            return null;
        }

    };
    const handleRegister = async (event) => {
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            event.stopPropagation();

            setRegistering(true);
            await apiFetch.post('/api/event/' + encodeURIComponent(eventCode) + '/register', registration)
                .then(resp => {
                    setRegisteredData(resp.data);
                    setRegistering(false);

                });
        }
        setValidated(true);
    };
    const handlePatternFormatChange = (values, sourceInfo) => {
        if (sourceInfo.source !== 'event') {
            return;
        }
        setRegistration({ ...registration, [sourceInfo.event.target.name]: values.value })
    };

    return (
        <>
            <div className="container-xl col-xxl-10 px-4 pt-1 pb-5">
                <div className="d-flex text-end pb-3">
                    <ol className="list-group breadcrumb p-2 me-auto">
                        <li className="breadcrumb-item">
                            <Link to="/events" className="link-body-emphasis fw-semibold text-decoration-none">
                                <FontAwesomeIcon icon={faArrowLeft} className="pe-2" />
                                <span className="">Back</span>
                            </Link>
                        </li>
                    </ol>
                </div>
                <div className="card mb-5">
                    <div className="row flex-lg-row align-items-center g-0 py-0 shadow-lg">
                        <div className="col-12 col-sm-12 col-lg-6 text-center">
                            <img style={{ maxHeight: '400px' }} src={eventData && eventData.imageUrl ? eventData.imageUrl : './assets/images/hyetech_logo2_4color.png'} className="img-fluid rounded-start" alt="..." />
                        </div>
                        <div className="col-12 col-sm-12 col-lg-6 ps-4">
                            <div className="card-body">
                                <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">{eventData.title}</h1>
                                <p className="lead pt-3">
                                    <FontAwesomeIcon icon={faCalendar} /> {getEventDates()} &nbsp;
                                    {getEventTimes() ? <><FontAwesomeIcon icon={faClock} /> {getEventTimes()}</> : null}
                                    {eventData.venue || eventData.room
                                        ?
                                        <>
                                            <br />
                                            <FontAwesomeIcon icon={faLocationDot} />&nbsp;
                                            {eventData.venue ?? eventData.room}
                                        </>
                                        :
                                        null}
                                </p>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                                    <button type="button" className="btn btn-primary btn-lg px-4 me-md-2" onClick={modalToggle}>Register</button>
                                    {/*<button type="button" className="btn btn-outline-secondary btn-lg px-4">Default</button>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="row flex-lg-row align-items-center g-5 py-5">*/}
                {/*    <div className="col-12 col-sm-12 col-lg-6">*/}
                {/*        <img src="https://www.shutterstock.com/shutterstock/photos/2062252346/display_1500/stock-vector-abstract-modern-business-conference-design-template-with-lines-minimal-flyer-layout-eps-vector-2062252346.jpg" className="d-block mx-lg-auto img-fluid" alt="" loading="lazy" />*/}
                {/*    </div>*/}
                {/*    <div className="col-12 col-sm-12 col-lg-6">*/}
                {/*        <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">{eventData.title}</h1>*/}
                {/*        <p className="lead pt-3">*/}
                {/*            <FontAwesomeIcon icon={faCalendar} /> {getEventDates()} &nbsp;*/}
                {/*            {getEventTimes() ? <><FontAwesomeIcon icon={faClock} /> {getEventTimes()}</> : null}*/}
                {/*            {eventData.venue || eventData.room*/}
                {/*                ?*/}
                {/*                <>*/}
                {/*                    <br />*/}
                {/*                    <FontAwesomeIcon icon={faLocationDot} />&nbsp;*/}
                {/*                    {eventData.venue ?? eventData.room}*/}
                {/*                </>*/}
                {/*                :*/}
                {/*                null}*/}
                {/*        </p>*/}
                {/*        <div className="d-grid gap-2 d-md-flex justify-content-md-start">*/}
                {/*            <button type="button" className="btn btn-primary btn-lg px-4 me-md-2" onClick={modalToggle}>Register</button>*/}
                {/*            */}{/*<button type="button" className="btn btn-outline-secondary btn-lg px-4">Default</button>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="row mt-5">
                    <div className="col-12 col-md-8 col-lg-8 pb-5">
                        <h3 className="text-muted text-secondary">Description</h3>
                        <div className="lead" style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: eventData.description }} />
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <h4 className="text-muted text-secondary">Location</h4>
                        {eventData && (eventData.venue || eventData.room || eventData.address || eventData.city || eventData.state || eventData.zip)
                            ?
                            <p className="lead">
                                {eventData.venue ? <>{eventData.venue}<br /></> : null}
                                {eventData.room ? <>{eventData.room}<br /></> : null}
                                {eventData.address ? <>{eventData.address}<br /></> : null}
                                {eventData.city ? <>{eventData.city}</> : null}{eventData.city && eventData.state ? ', ' : null} {eventData.state ? <>{eventData.state}</> : null} {eventData.zip ? <>{eventData.zip}</> : null}{eventData.city || eventData.state || eventData.zip ? <br /> : null}
                            </p>
                            :
                            'TBD'
                        }
                    </div>
                </div>
            </div>
            <Modal isOpen={modal} toggle={modalToggle} size="md" fade={true}>
                <ModalHeader toggle={modalToggle}>
                    <FontAwesomeIcon icon={faCalendar} className="pe-2" />Event Registration
                </ModalHeader>
                <ModalBody className="">
                    {registeredData ?
                        <div className="py-5 text-center">
                            <img className="d-block mx-auto mb-4" src="./assets/images/hyetech_logo2_4color.png" alt="" height="200" />
                            <h2 className="text-success">Success!</h2>
                            <p className="lead">
                                You have successfully registered for this event.
                            </p>
                        </div>
                        :
                        <div className="col">
                            <h4 className="mb-3">Your Info</h4>
                            <form noValidate className={`needs-validation ${validated ? 'was-validated' : ''}`} onSubmit={handleRegister}>
                                <div className="row g-3">
                                    <div className="col-sm-6">
                                        <label htmlFor="firstName" className="form-label">First Name</label>
                                        <input type="text" name="firstName" value={registration.firstName} onChange={(e) => setRegistration({ ...registration, [e.target.name]: e.target.value })} className="form-control" id="firstName" required />
                                        <div className="invalid-feedback">
                                            Valid first name is required.
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="lastName" className="form-label">Last Name</label>
                                        <input type="text" name="lastName" value={registration.lastName} onChange={(e) => setRegistration({ ...registration, [e.target.name]: e.target.value })} className="form-control" id="lastName" placeholder="" required />
                                        <div className="invalid-feedback">
                                            Valid last name is required.
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="Company" className="form-label">Company</label>
                                        <input type="text" name="company" value={registration.company} onChange={(e) => setRegistration({ ...registration, [e.target.name]: e.target.value })} className="form-control" id="Company" placeholder="" required />
                                        <div className="invalid-feedback">
                                            Your username is required.
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input type="email" name="email" value={registration.email} onChange={(e) => setRegistration({ ...registration, [e.target.name]: e.target.value })} className="form-control" id="email" placeholder="you@company.com" required />
                                        <div className="invalid-feedback">
                                            Please enter a valid email address.
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="phone" className="form-label">Phone</label>
                                        <PatternFormat name="phone" onValueChange={handlePatternFormatChange} className="form-control" id="phone" format="+1 (###) ###-####" value={registration.phone} valueIsNumericString={true} allowEmptyFormatting mask="_" />
                                        <div className="invalid-feedback">
                                            Please enter a valid phone number.
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4" />

                                <button className="w-100 btn btn-primary btn-lg" type="submit" disabled={registering}>
                                    {registering ? <FontAwesomeIcon icon={faSpinner} className='me-3' spin /> : null}
                                    Register
                                </button>
                            </form>
                        </div>
                    }

                </ModalBody>
            </Modal>
        </>
    );
};
