import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import useApiFetch from '../ApiFetch';


import { Link, Route, Routes } from 'react-router-dom';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';

//import { GetAuthUsername } from "../AuthUsername";

//import Header from "./Header";
//import SubMenu from "./SubMenu";
//import Find from "./Find";

import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faStar } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faCalendarDays, faUser, faMagnifyingGlass, faSpinner } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';


export default function StayConnected() {
    const apiFetch = useApiFetch();

    const [signUp, setSignUp] = useState({ firstName: '', lastName: '', email: '' });
    const [signingUp, setSigningUp] = useState(false);
    const [signedUp, setSignedUp] = useState(null);
    const [validated, setValidated] = useState(false);


    const [events, setEvents] = useState([]);
    const [months, setMonths] = useState([]);

    const [searching, setSearching] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [searchFor, setSearchFor] = useState('');

    const [modalCompetitorDetail, setModalCompetitorDetail] = useState(false);
    const [competitorDetail, setCompetitorDetail] = useState(null);

    //const authUsername = GetAuthUsername();

    useEffect(() => {

    }, []);

    const handleSignUp = async (event) => {
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            event.stopPropagation();

            setSigningUp(true);
            await apiFetch.post('/api/list/signup', signUp)
                .then(resp => {
                    setSignedUp(resp.data);
                    setSigningUp(false);
                });
        }
        setValidated(true);
    };

    return (
        <>
            <div className="container-xl col-xl-10 col-xxl-8 px-4 pt-2 pb-5">
                <div className="row align-items-center g-lg-5 py-5">
                    <div className="col-lg-7 text-center text-lg-start">
                        <h1 className="display-4 fw-bold lh-1 text-body-emphasis mb-3">Stay up to date</h1>
                        <p className="col-lg-10 fs-4">Keep informed of upcoming events.  Sign up to receive information on upcoming events that you may not want to miss.</p>
                    </div>
                    <div className="col-md-10 mx-auto col-lg-5">
                        {signedUp ?
                            <div className="p-3 p-md-5 border rounded-3 bg-body-tertiary">
                            <div className="py-0 text-center">
                                <img className="d-block mx-auto mb-4" src="./assets/images/hyetech_logo2_4color.png" alt="" height="200" />
                                <h2 className="text-success">Success!</h2>
                                <p className="lead">
                                    You have successfully signed up to receive news about events.
                                </p>
                                </div>
                            </div>
                            :

                            <form noValidate className={`p-4 p-md-5 border rounded-3 bg-body-tertiary needs-validation ${validated ? 'was-validated' : ''}`} onSubmit={handleSignUp}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-floating mb-3">
                                            <input type="text" name="firstName" className="form-control" id="firstName" placeholder="" value={signUp.firstName} onChange={(e) => setSignUp({ ...signUp, [e.target.name]: e.target.value })} />
                                            <label htmlFor="firstName" className="form-label">First Name</label>
                                            {/*<div className="invalid-feedback">*/}
                                            {/*    First name is required.*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-floating mb-3">
                                            <input type="text" name="lastName" className="form-control" id="lastName" placeholder="" value={signUp.lastName} onChange={(e) => setSignUp({ ...signUp, [e.target.name]: e.target.value })} />
                                            <label htmlFor="lastName" className="form-label">Last Name</label>
                                            {/*<div className="invalid-feedback">*/}
                                            {/*    Last name is required.*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    <div className="col-sm-12">

                                        <div className="form-floating mb-3">
                                            <input type="email" name="email" value={signUp.email} onChange={(e) => setSignUp({ ...signUp, [e.target.name]: e.target.value })} className="form-control" id="email" placeholder="you@company.com" required />
                                            <label htmlFor="email">Email</label>
                                            <div className="invalid-feedback">
                                                Valid email address required.
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <button className="w-100 btn btn-lg btn-primary" type="submit" disabled={signingUp} >
                                    {signingUp ? <FontAwesomeIcon icon={faSpinner} className='me-3' spin /> : null}
                                    Sign Up
                                </button>
                                <hr className="my-4" />
                                <small className="text-body-secondary">By clicking Sign Up, you agree to the terms.</small>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </>
    );

}
