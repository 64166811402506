import React, { Component, useState, useEffect } from 'react';
import {
    Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink,
    UncontrolledDropdown,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import './NavMenu.css';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';

import { faUser, faHeart, faStar, faTrashCan, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { faUser as faUserSolid, faCamera, faMagnifyingGlass, faSpinner, faFileInvoice, faUsersLine, faBan, faBook, faFloppyDisk, faCashRegister } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useApiFetch } from '../ApiFetch';
import DarkModeDropdown from './DarkModeDropdown';

export const NavMenu = () => {
    const signOut = useSignOut();
    const authUser = useAuthUser();
    const authHeader = useAuthHeader();
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const apiFetch = useApiFetch();


    const [collapsed, setCollapsed] = useState(true);

    const [profileStudents, setProfileStudents] = useState([]);

    const toggleNavbar = () => {
        setCollapsed(!collapsed)
    }
    const handleLogin = () => {
        //instance.loginRedirect(loginRequest);
    }
    const handleLogout = (e) => {
        e.preventDefault();
        signOut()
        navigate('/');
    };

    useEffect(() => {
        if (isAuthenticated()) {
            //get the students for the profile
            getStudents();
        }

    }, [authUser]);

    const getStudents = async () => {
        //const response = await fetch('/api/profile/students', {
        //    headers: {
        //        'Authorization': authHeader,
        //    }
        //});
        //const rdata = await response.json();
        await apiFetch.get('/api/profile/students')
            .then((resp) => setProfileStudents(resp.data));
    };

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3 py-0" container style={{ backgroundColor: '#1295d8' }}>
                <NavbarBrand tag={Link} to="/">
                    <img src="/assets/images/hyetech_logo2_4color.png" alt="HyeTech" height="75" />
                    <span className="ms-3 mt-3 h3" style={{ lineHeight: '75px' }}>HyeTech Events</span>
                    {/*https://s3.us-east-1.amazonaws.com/tw-desk/i/359076/doccontent/ec0badbd-336e-477b-b36d-ec96f4dffb61.png*/}
                    {/*https://hyetechnetworks.com/wp-content/uploads/2021/09/hyetech_logo2_4color.png*/}
                </NavbarBrand>
                <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                    {/*<ul className="navbar-nav flex-grow">*/}
                    <Nav navbar>
                        <NavItem>
                            <NavLink tag={Link} to="/">Home</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to="/events">Events</NavLink>
                        </NavItem>
                        {isAuthenticated() ?
                            <>
                                <NavItem>
                                    <NavLink tag={Link} to="/tournament">Tournament Registration</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to="/pos">POS</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to="/competitors">Competitors</NavLink>
                                </NavItem>
                            </>
                            : null}
                        {/*<UncontrolledDropdown nav>*/}
                        {/*    <DropdownToggle nav caret>*/}
                        {/*        {authUser ?*/}
                        {/*            <>*/}
                        {/*                <FontAwesomeIcon icon={faUserSolid} />*/}
                        {/*            </>*/}
                        {/*            :*/}
                        {/*            <>*/}
                        {/*                <FontAwesomeIcon icon={faUser} />*/}
                        {/*            </>*/}
                        {/*        }*/}
                        {/*    </DropdownToggle>*/}
                        {/*    <DropdownMenu flip tag="ul">*/}
                        {/*        {authUser ?*/}
                        {/*            <>*/}
                        {/*                <DropdownItem text tag="li">Welcome{authUser.profile && authUser.profile.firstName ? ', ' + authUser.profile.firstName : '!'}</DropdownItem>*/}
                        {/*                <DropdownItem divider tag="li" />*/}
                        {/*                <DropdownItem tag={Link} to="/profile">Profile</DropdownItem>*/}
                        {/*                <DropdownItem divider tag="li" />*/}
                        {/*                {profileStudents && profileStudents.length > 0*/}
                        {/*                    ?*/}
                        {/*                    <>*/}
                        {/*                        {profileStudents.map((s, i) => { return <DropdownItem tag="li" key={"nav-student-" + s.studentId} >{s.firstName} {s.lastName}</DropdownItem> })}*/}
                        {/*                        <DropdownItem divider tag="li" />*/}
                        {/*                    </>*/}
                        {/*                    :*/}
                        {/*                    null*/}
                        {/*                }*/}
                        {/*                <DropdownItem tag="li" onClick={handleLogout}>Log Out</DropdownItem>*/}
                        {/*            </>*/}
                        {/*            :*/}
                        {/*            <>*/}
                        {/*                <DropdownItem tag={Link} to="/profile/register" >Register</DropdownItem>*/}
                        {/*                <DropdownItem divider tag="li" />*/}
                        {/*                <DropdownItem tag={Link} to="/login" >Log In</DropdownItem>*/}
                        {/*            </>*/}
                        {/*        }*/}
                        {/*    </DropdownMenu>*/}
                        {/*</UncontrolledDropdown>*/}
                        {/*<DarkModeDropdown />*/}
                    </Nav>
                </Collapse>
            </Navbar>
        </header>
        );
}
