import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCalendar, faClock, faLocationDot, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';
import moment from 'moment';
import { NumericFormat, PatternFormat } from 'react-number-format';
import useApiFetch from '../../ApiFetch';

export default function EventHero(props) {
    const apiFetch = useApiFetch();

    const [loading, setLoading] = useState(false);
    const [eventData, setEventData] = useState([]);

    useEffect(() => {
        setLoading(true);
        getData();
    }, []);
    const getData = async () => {
        await apiFetch.get('/api/event/')
            .then(resp => {
                let data = resp.data;
                if (data && data.length > 0) {
                    data = data.sort(function (a, b) {
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        return new Date(a.startDate) - new Date(b.startDate);
                    })
                }
                setEventData(resp.data[0]);
                setLoading(false);
            });
    };
    const getEventDates = () => {
        if (eventData) {
            let returnText = null;
            if (eventData.endDate) {
                //if we have an end date, check to see if the start and end dates are the same, if they are, show just one date
                if (eventData.endDate === eventData.startDate) {
                    returnText = moment(eventData.startDate).format('MMMM D, YYYY');
                }
                else {
                    //if the month is the same, show Month Start-End, otherwise, show Month Start - Month End
                    if (moment(eventData.startDate).month() === moment(eventData.endDate).month()) {
                        returnText = moment(eventData.startDate).format('MMMM') + ' ' + moment(eventData.startDate).format('D') + '-' + moment(eventData.endDate).format('D');
                        if (moment(eventData.startDate).year() === moment(eventData.endDate).year()) {
                            returnText += ', ' + moment(eventData.startDate).year();
                        }
                    }
                    else {
                        if (moment(eventData.startDate).year() === moment(eventData.endDate).year()) {
                            returnText = moment(eventData.startDate).format('MMMM D') + '-' + moment(eventData.endDate).format('MMMM D') + ', ' + moment(eventData.startDate).year();
                        }
                        else {
                            returnText = moment(eventData.startDate).format('MMMM D, YYYY') + ' - ' + moment(eventData.endDate).format('MMMM D, YYYY');
                        }
                    }
                }
            }
            else {
                returnText = moment(eventData.startDate).format('MMMM D, YYYY');
            }
            return returnText;
        }

    };

    return (
        <>
            {eventData
                ?
                <div className="container-xl p-2">
                    <div className="p-5 text-center bg-body-tertiary rounded-3 m-3">
                        {loading ?
                            <>
                                <div className="my-5">
                                    <div className="p-5 text-center">
                                        <div className="container py-5 text-muted">
                                            <h1 className="display-1 text-body-muted"><FontAwesomeIcon icon={faSpinner} spin /></h1>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                {/*<svg className="bi mt-4 mb-3" style="color: var(--bs-indigo);" width="100" height="100"><use xlink: href="#bootstrap"></use></svg>*/}
                                <img className="bi mt-4 mb-3 img img-fluid" style={{ maxHeight: '300px' }} src={eventData && eventData.imageUrl ? eventData.imageUrl : './assets/images/hyetech_logo2_4color.png'} alt={eventData ? eventData.title : null} />

                                <h1 className="text-body-emphasis">{eventData ? eventData.title : null}</h1>
                                <p className="col-lg-8 mx-auto fs-5 text-muted">
                                    {getEventDates()}
                                </p>
                                {/*<p className="col-lg-8 mx-auto fs-5 text-muted">*/}
                                {/*    This is a custom jumbotron featuring an SVG image at the top, some longer text that wraps early thanks to a responsive <code>.col-*</code> class, and a customized call to action.*/}
                                {/*</p>*/}
                                <div className="row d-flex gap-2 mb-5 justify-content-center">
                                    <div className="col-auto">
                                        <Link to={'/events/' + eventData.code} className="d-inline-flex align-items-center btn btn-primary btn-sm-lg btn-lg px-4" type="button">
                                            Event Details
                                            <FontAwesomeIcon icon={faArrowRight} className="ms-2 fs-6" />
                                        </Link>
                                    </div>
                                    {/*<div className="col-auto">*/}
                                    {/*    <a href="https://www.ufaf.org/tournament/" target="_new" className="btn btn-outline-secondary btn-lg px-4">View Event</a>*/}
                                    {/*</div>*/}
                                </div>
                            </>
                        }

                    </div>
                </div>
                :
                null
            }
        </>
    );
};
