import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCalendar, faClock, faLocationDot, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';
import moment from 'moment';
import { NumericFormat, PatternFormat } from 'react-number-format';
import useApiFetch from '../../ApiFetch';

export default function HomeEventCards(props) {
    const { offset, numberOfCards } = props;
    const apiFetch = useApiFetch();

    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        setLoading(true);

        getData();
    }, []);
    const getData = async () => {
        await apiFetch.get('/api/event/')
            .then(resp => {
                let data = resp.data;
                if (data && data.length > 0) {
                    data = data.sort(function (a, b) {
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        return new Date(a.startDate) - new Date(b.startDate);
                    })

                    if (data.length >= (offset ?? 1) + 1) {
                        data = data.slice(offset ?? 1, (numberOfCards ?? 3) + (offset ?? 1));
                    }
                    else {
                        data = null;
                    }
                }
                setEvents(data);
                setLoading(false);
            });
    };
    const getEventDates = (event) => {
        if (event) {
            let returnText = null;
            if (event.endDate) {
                //if we have an end date, check to see if the start and end dates are the same, if they are, show just one date
                if (event.endDate === event.startDate) {
                    returnText = moment(event.startDate).format('MMMM D, YYYY');
                }
                else {
                    //if the month is the same, show Month Start-End, otherwise, show Month Start - Month End
                    if (moment(event.startDate).month() === moment(event.endDate).month()) {
                        returnText = moment(event.startDate).format('MMMM') + ' ' + moment(event.startDate).format('D') + '-' + moment(event.endDate).format('D');
                        if (moment(event.startDate).year() === moment(event.endDate).year()) {
                            returnText += ', ' + moment(event.startDate).year();
                        }
                    }
                    else {
                        if (moment(event.startDate).year() === moment(event.endDate).year()) {
                            returnText = moment(event.startDate).format('MMMM D') + '-' + moment(event.endDate).format('MMMM D') + ', ' + moment(event.startDate).year();
                        }
                        else {
                            returnText = moment(event.startDate).format('MMMM D, YYYY') + ' - ' + moment(event.endDate).format('MMMM D, YYYY');
                        }
                    }
                }
            }
            else {
                returnText = moment(event.startDate).format('MMMM D, YYYY');
            }
            return returnText;
        }

    };

    return (
        <>
            {loading ?
                <>
                    <div className="my-5">
                        <div className="p-5 text-center">
                            <div className="container py-5 text-muted">
                                <h1 className="display-1 text-body-muted"><FontAwesomeIcon icon={faSpinner} spin /></h1>
                            </div>
                        </div>
                    </div>
                </>
                :
                null
            }
            {events && events.length > 0 && !loading
                ?
                <div className="container-xl px-2 pb-4">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 px-3 py-5">
                        {events.map(e =>
                            <div key={e.id} className="col-md-4">
                                <div className="card shadow-sm h-100">
                                    <img src={e.imageUrl ? e.imageUrl : './assets/images/hyetech_logo2_4color.png'} className="bd-placeholder-img card-img-top" alt="" />
                                    <div className="card-body">
                                        <h5 className="card-title">{e.title}</h5>
                                        <h6>{getEventDates(e)}</h6>
                                        <p className="card-text" dangerouslySetInnerHTML={{ __html: e.shortDescription }} />
                                        <Link to={'/events/' + e.code} className="btn btn-primary" type="button">
                                            Event Details
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                :
                null
            }
        </>
    );
};
