import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faSpinner } from '@fortawesome/free-solid-svg-icons';
import useApiFetch from '../../ApiFetch';

function ProfilePassword(props) {
    const apiFetch = useApiFetch();

    const [profileChangePassword, setProfileChangePassword] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' });

    const [savingProfilePassword, setSavingProfilePassword] = useState(false);
    const [profilePasswordSaveResult, setProfilePasswordSaveResult] = useState(null);
    const [profilePasswordError, setProfilePasswordError] = useState(null);

    useEffect(() => {
    }, []);

    const saveProfilePassword = async (e) => {
        e.preventDefault();
        setSavingProfilePassword(true);
        setProfilePasswordError(null);

        await apiFetch.post('/api/auth/changepassword', profileChangePassword)
            .then((resp) => {
                setProfilePasswordSaveResult('Success');
                setTimeout(() => { setProfilePasswordSaveResult(null); }, 5000);
            })
            .catch((ex) => {
                setProfilePasswordSaveResult('Fail');
                setTimeout(() => { setProfilePasswordSaveResult(null); }, 5000);
                setProfilePasswordError(ex.response && ex.response.data && ex.response.data.error ? ex.response.data.error.message : null);
                setTimeout(() => { setProfilePasswordError(null); }, 5000);
            })
            .finally(() => {
                setProfileChangePassword({ currentPassword: '', newPassword: '', confirmPassword: '' });
                setSavingProfilePassword(false);
            });
    };

    return (
        <form onSubmit={saveProfilePassword} className="card h-100">
            <div className="card-header">
                Change Password
            </div>
            <div className="card-body">
                <div className="row g-3">
                    <div className="col-sm-12">
                        <label htmlFor="password" className="form-label">Current Password</label>
                        <input type="password" className={`form-control ${profileChangePassword.currentPassword.length < 6 ? 'is-invalid' : 'is-valid'}`} id="currentPassword" onChange={(e) => setProfileChangePassword({ ...profileChangePassword, [e.target.name]: e.target.value })} name="currentPassword" value={profileChangePassword.currentPassword} />
                    </div>
                    <div className="col-sm-12">
                        <label htmlFor="newPassword" className="form-label">New Password</label>
                        <input type="password" className={`form-control ${profileChangePassword.newPassword.length < 6 ? 'is-invalid' : 'is-valid'}`} id="newPassword" onChange={(e) => setProfileChangePassword({ ...profileChangePassword, [e.target.name]: e.target.value })} name="newPassword" value={profileChangePassword.newPassword} />
                    </div>
                    <div className="col-sm-12">
                        <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                        <input type="password" className={`form-control ${profileChangePassword.newPassword === profileChangePassword.confirmPassword && profileChangePassword.confirmPassword !== '' ? 'is-valid' : 'is-invalid'}`} id="confirmPassword" onChange={(e) => setProfileChangePassword({ ...profileChangePassword, [e.target.name]: e.target.value })} name="confirmPassword" value={profileChangePassword.confirmPassword} />
                    </div>
                    {profilePasswordError ?
                        <div className="col-sm-12 text-danger">
                            {profilePasswordError}
                        </div>
                        : null}
                </div>
            </div>
            <div className="card-footer text-body-secondary text-end">
                <button type="submit" className="btn btn-secondary btn-sm position-relative" disabled={profileChangePassword.currentPassword === '' || profileChangePassword.newPassword.length < 6 || profileChangePassword.newPassword !== profileChangePassword.confirmPassword}>
                    <FontAwesomeIcon icon={savingProfilePassword ? faSpinner : faFloppyDisk} spin={savingProfilePassword} className="me-2" />
                    Save
                    {profilePasswordSaveResult
                        ?
                        <span className={`position-absolute top-0 start-100 translate-middle p-2 border border-light rounded-circle ${profilePasswordSaveResult === 'Success' ? "bg-success" : profilePasswordSaveResult === 'Fail' ? "bg-danger" : ""}`}>
                            <span className="visually-hidden">Saved</span>
                        </span>
                        : null
                    }
                </button>
            </div>
        </form>
    );

}
export default ProfilePassword;
