import React, { useState, useEffect, Fragment } from 'react';
import ReactDOM from 'react-dom';
import useApiFetch from '../../ApiFetch';


import { Link, Route, Routes } from 'react-router-dom';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';

//import { GetAuthUsername } from "../AuthUsername";

//import Header from "./Header";
//import SubMenu from "./SubMenu";
//import Find from "./Find";

import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
import { faHeart, faStar } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faCalendarDays, faUser, faMagnifyingGlass, faSpinner } from '@fortawesome/free-solid-svg-icons';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
//import { DataTableFunc } from "../DataTableFunc"
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import EventCard from './EventCard';
import StayConnected from '../StayConnected';


export default function Events() {
    const apiFetch = useApiFetch();

    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const [months, setMonths] = useState([]);

    const [modalCompetitorDetail, setModalCompetitorDetail] = useState(false);

    //const authUsername = GetAuthUsername();

    const toggleCompetitorDetail = () => setModalCompetitorDetail(!modalCompetitorDetail);

    useEffect(() => {
        setLoading(true);
        getData();
    }, []);
    const getData = async () => {
        await apiFetch.get('/api/event/')
            .then(resp => {
                let data = resp.data;
                if (data && data.length > 0) {
                    data = data.sort(function (a, b) {
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        return new Date(a.startDate) - new Date(b.startDate);
                    })
                }
                setEvents(resp.data);
                if (resp.data && resp.data.length > 0) {
                    let dates = resp.data.map(e => {
                        let thisMonth = moment().set('month', moment(e.startDate).month());
                        thisMonth = thisMonth.set('date', 1);
                        thisMonth = thisMonth.set('year', moment(e.startDate).year());
                        thisMonth = thisMonth.startOf('day');
                        return thisMonth.format('L');
                    });

                    let datesArray = Array.from([...new Set(dates)]).filter((v, i, self) => {
                        // It returns the index of the first
                        // instance of each value
                        return i === self.indexOf(moment(v).format('L'));
                    })

                    setMonths(datesArray);

                }
                setLoading(false);
            });
    };
    return (
        <>
            {/*<div className="b-example-divider mb-4">*/}
            {/*    <div className="container-xl">*/}
            {/*        <h2>*/}
            {/*            <FontAwesomeIcon icon={faCalendarDays} className="me-3" />*/}
            {/*            Events*/}
            {/*        </h2>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="container-xl px-2 pb-4">
                <div className="p-1 pb-4 text-center bg-body-tertiary rounded-3 m-3">
                    <img className="bi mt-1 mb-1" src="./assets/images/hyetech_logo2_4color.png" alt="HyeTech" height="200" />
                    <h1 className="text-body-emphasis">HyeTech Events</h1>
                    <p className="col-lg-8 mx-auto fs-5 text-muted">
                        HyeTech's events are an excellent opportunity to learn about technology trends from experts that can help you navigate challenges.
                    </p>
                </div>
                <div className="row mb-3 dashboard justify-content-center">
                </div>
            </div>

            <div className="container-xl pb-4">
                <div className="row g-3">
                    {months && months.length > 0 ?
                        months.sort(function (a, b) {
                            // Turn your strings into dates, and then subtract them
                            // to get a value that is either negative, positive, or zero.
                            return new Date(a.date) - new Date(b.date);
                        }).map(m =>
                            <Fragment key={m}>
                                <h2 className="pb-2 border-bottom text-end text-secondary">{moment(m).format("MMMM YYYY")}</h2>
                                <div className="row g-2 pt-2 pb-5 row-cols-1 row-cols-lg-3">
                                    {events && events.length > 0 ?
                                        events.filter(x => moment(x.startDate).month() === moment(m).month() && moment(x.startDate).year() === moment(m).year()).map(e =>
                                            <EventCard key={e.id} event={e} />
                                        )
                                        :
                                        null
                                    }
                                </div>
                            </Fragment>
                        )
                        :
                        null
                    }
                </div>
            </div>
            {loading ?
                <>
                    <div className="b-example-divider mb-4"></div>
                    <div className="my-5">
                        <div className="p-5 text-center">
                            <div className="container py-5 text-muted">
                                <h1 className="display-1 text-body-muted"><FontAwesomeIcon icon={faSpinner} spin /></h1>
                            </div>
                        </div>
                    </div>
                </>
                :
                null
            }
            {!loading && (!months || months.length) < 1 ?
                <>
                    <div className="b-example-divider mb-4"></div>
                    <div className="my-5">
                        <div className="p-5 text-center">
                            <div className="container py-5 text-muted">
                                <h1 className="text-body-muted">No Events Scheduled</h1>
                                <p className="col-lg-8 mx-auto lead text-muted">
                                    The events team is working hard on upcoming events.  Please check back often for new event info.
                                </p>
                            </div>
                        </div>
                    </div>
                </>
                :
                null
                }
            <div className="b-example-divider mb-4"></div>
            <StayConnected />
        </>
    );

}
