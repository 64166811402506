import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faStar, faUserPen } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import useApiFetch from '../../ApiFetch';

export default function StudentCard(props) {
    const { student, actions, showCloseButton, closeAction } = props;
    const apiFetch = useApiFetch();

    useEffect(() => {

    }, []);

    const onCloseAction = async (e) => {
        e.preventDefault();
        if (closeAction)
            closeAction();
    }

    return (
        <div className="col" key={"student-" + student.studentId}>
            <div className="card h-100 ">
                <div className="row g-0">
                    <div className="col-md-4">
                        {/*<img src="https://www.sidekick.events/assets/images/sidekick_logo_right.png" className="img-fluid rounded-start" alt="..." />*/}

                        {/*d-md-block*/}
                        <div className="col d-sm-none">
                            <div className="hex mt-3 bg-red mr-4" style={{ width: "204px", height: "184px" }} >
                                <div className="hex-background" style={{ top: "2px", left: "2px", width: "200px", height: "180px" }} >
                                    <img className="img img-fluid" style={{ position: "relative" }} src={student.imageUrl ?? 'https://ik.imagekit.io/sidekick/sidekick_default_competitor.png?tr=small_profile'} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col d-none d-sm-block"> {/*d-md-none*/}
                            <div className="hex mt-3 bg-red mr-4" style={{ width: "104px", height: "94px" }} >
                                <div className="hex-background" style={{ top: "2px", left: "2px", width: "100px", height: "90px" }} >
                                    <img className="img img-fluid" style={{ position: "relative" }} src={student.imageUrl ?? 'https://ik.imagekit.io/sidekick/sidekick_default_competitor.png?tr=small_profile'} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                            {student.isPrimary ?
                                <FontAwesomeIcon icon={faStar} className="float-end text-warning" />
                                :
                                showCloseButton ?
                                    <Link onClick={onCloseAction}>
                                        <FontAwesomeIcon icon={faXmark} className="float-end" />
                                    </Link>
                                    :
                                    null
                            }
                            <h5 className="card-title">{student.firstName} {student.lastName}</h5>
                            <p className="card-text">
                                {student.rankName}<br />
                                Competitor Age: {student.competitionAge}
                            </p>
                            <hr className="mb-2" />
                            <div className="col align-self-end text-truncate"><h5>{student.schoolName}</h5><h6>{student.studentCity}, {student.studentState}</h6></div>
                        </div>
                        <div className="card-footer border-0 bg-transparent">
                            <p className="card-text text-end">
                                {actions && actions.length > 0 ?
                                    actions.map((a, i) =>
                                        <small key={i} className="text-body-secondary mx-1">
                                            {a}
                                        </small>
                                    )
                                    :
                                    null
                                }
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

}
