import React, { Component } from 'react';
import { BrowserRouter, Route, useNavigate, Outlet } from 'react-router-dom';
import Routes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { Login }  from './components/Login';
import { Home } from "./components/Home";
import AuthProvider from 'react-auth-kit';
import createStore from 'react-auth-kit/createStore';

/*import AuthProvider from './AuthContext';*/
import RoutesComponent from './AppRoutes';


const store = createStore({
    authName: '_auth',
    authType: 'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:',
})

export default function App() {
    //const { loggedIn }  = useAuth();

    return (
        <AuthProvider store={store}>
            {/*<Layout>*/}
                    <RoutesComponent />
            {/*</Layout>*/}
        </AuthProvider>
    );
}

//const PrivateRoute = ({ component: Component, loggedIn, ...rest }) => {
//    const auth = loggedIn;  

//    return !loggedIn ? <Component loggedIn={loggedIn} {...rest} /> : <Login />;
//};
//const PrivateRoute = ({ component: Component, loggedIn, ...rest }) => (
//    <Route
//        {...rest}
//        render={(props) =>
//            loggedIn ? <Component {...props} /> : <Login />
//        }
//    />
//);
