import React, { Component } from 'react';
import { faHeart, faStar, faTrashCan, faPenToSquare  } from '@fortawesome/free-regular-svg-icons';
import { faCamera, faMagnifyingGlass, faArrowRight, faSpinner, faFileInvoice, faUsersLine, faBan, faBook, faFloppyDisk, faCashRegister } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, Link } from 'react-router-dom';
import EventHero from "./events/EventHero";
import HomeEventCards from "./events/HomeEventCards";
import StayConnected from './StayConnected';

export class Home extends Component {
    constructor(props) {
        super(props)
    }
    static displayName = Home.name;

    render() {
        return (
            <>
                <div className="b-example-divider"></div>
                {/*<div className=" bg-light text-dark">*/}
                <div className="container col-xxl-8 px-4 py-5">
                    <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                        <div className="col-10 col-sm-8 col-lg-6">
                            <img src="./assets/images/MeetingRoom.jpeg" className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy" />
                        </div>
                        <div className="col-lg-6">
                            <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">Welcome!</h1>
                            <p className="lead"><b>HyeTech Events</b> is your go-to destination for discovering the latest technology events tailored for professionals, clients, and partners alike. Our mission is to foster a vibrant environment where innovation thrives, offering you opportunities to stay ahead with cutting-edge tech insights, engage in meaningful networking, and expand your professional horizons. Join us to explore our diverse range of events designed to enhance your knowledge, connect with industry leaders, and collaboratively shape the future of technology.</p>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                                <Link to='/events' className="btn btn-primary btn-lg px-4 me-md-2" type="button">
                                    Events Scheduled
                                    <FontAwesomeIcon icon={faArrowRight} className="ms-2 fs-6" />
                                </Link>
                                {/*<button type="button" className="btn btn-outline-secondary btn-lg px-4">Default</button>*/}
                            </div>
                        </div>
                    </div>
                    </div>
                {/*</div>*/}
                <div className="b-example-divider"></div>
                <EventHero />
                <HomeEventCards offset={1} numberOfCards={3} />
                <div className="b-example-divider mb-4"></div>
                <StayConnected />
            </>
        );
    }
}
